import { MaterialCommunityIcons } from "@expo/vector-icons"
import { buildAddressDescription } from 'lib/addressHelpers'
import { CardModal } from "components/Layout/CardModal"
import { Headline, Paragraph, Subheading } from "components/Typography"
import { BulletItem } from "components/Typography/BulletItem"
import { Button } from "components/Utility/Button"
import { ConfirmationDialog } from "components/Utility/ConfirmationDialog"
import { ErrorScreen } from 'components/Utility/ErrorScreen'
import { ExternalLinkText } from "components/Utility/ExternalLinkText"
import { FooterButton } from "components/Utility/FooterButton"
import { Loading } from "components/Utility/Loading"
import { format, isBefore, subWeeks } from "date-fns"
import { mainAppNavigate, rootNavigate } from "lib/RootNavigation"
import { getAmplitudeRetirementAssetAttributes } from "lib/amplitudeHelpers"
import { JAR_NAME_ALL, JAR_NAME_PERSONAL, PENDING_TRANSFER_CONSIDERED_STALE_WEEKS, PERSONAL_TAX_RELIEF_FACTOR } from "lib/constants"
import { getActionDate } from "lib/dateHelpers"
import { formatCurrencyAmount } from "lib/generalHelpers"
import { MainAppNavScreen } from "lib/navigationHelpers"
import { orderBy, startCase, tail } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Image, ScrollView, StyleSheet, View } from "react-native"
import { ampli } from "src/ampli"
import { useAcknowledgeTransferRetirementAssetMutation, useApproveTransferRetirementAssetMutation, useCancelTransferRetirementAssetMutation, useConfirmProgressTransferRetirementAssetMutation, useGetPensionBrandsQuery, useGetPensionProvidersQuery, useGetRetirementAssetPolicyDocumentDetailsQuery, useGetUserAccountsQuery } from "store/apiSlice"
import { ContributionSource } from "store/dto/base.dto"
import { ClientMeDto } from "store/dto/client.dto"
import { AccountDto, AccountType, PensionTransferStatus } from "store/dto/account.dto"
import { RetirementAssetDto, RetirementAssetTracingStatus, RetirementAssetTransferAttempt, RetirementAssetTransferStatus, RetirementAssetType } from "store/dto/retirement-asset.dto"
import { useAppDispatch } from "store/hooks"
import { MakeContributionsStepIdentifier, setMakeContributionsData } from "store/uxSlice"
import { Colors, Flex, Sizing, Typography } from "styles"
import { layoutStyles } from "styles/common"
import { TransferAssistanceModal } from './TransferAssistanceModal'
import { Portal } from "react-native-paper"
import { envVariables } from "environment"
import { getPersonalPension } from "lib/accountHelpers"
import { ContentDivider } from "components/Layout/ContentDivider"
import { platformIsWeb } from 'lib/platformHelpers'

const isWeb = platformIsWeb()

type TransferInfoModalProps = {
  asset: RetirementAssetDto
  client: ClientMeDto
  pension: AccountDto
  visible: boolean
  onDismiss: any
}

export const TransferInfoModal = (props: TransferInfoModalProps) => {
  const { asset, client, pension, visible, onDismiss } = props || {}
  const {
    id,
    name,
    pensionProviderId,
    externalProviderReference,
    pensionProviderBrandName,
    contributionSource,
    monthlyContributionAmount,
    assetType,
    transferAddress,
    employerName,
    employerStartDate,
    employerEndDate,
    currentValue,
    transferAttempts,
    transferStatus,
    tracingAttempts,
    tracingStatus,
    transferDelayExecutionDate,
    completedTransferAcknowledged,
    transferProgressConfirmed,
  } = asset || {}

  const dispatch = useAppDispatch()

  const { pensionFindingTermsUrl, personalPensionMemberDeclarationUrl } = envVariables

  const [confirmProgressDialogVisible, setConfirmProgressDialogVisible] = useState(false)
  const [approveDialogVisible, setApproveDialogVisible] = useState(false)
  const [cancelDialogVisible, setCancelDialogVisible] = useState(false)
  const [assistanceModalVisible, setAssistanceModalVisible] = useState(false)

  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const { data: providers, error: providersError, isLoading: providersIsLoading, refetch: refetchProviders } = useGetPensionProvidersQuery()
  const { data: accounts, error: accountsError, isLoading: accountsIsLoading, refetch: refetchAccounts } = useGetUserAccountsQuery()

  const personalPension = getPersonalPension(accounts)

  const isLoading = brandsIsLoading || providersIsLoading || accountsIsLoading
  const error = brandsError || providersError || accountsError


  const provider = providers ? providers.find(provider => {
    return provider.id === pensionProviderId
  }) : undefined

  const providerBrand = provider && brands ? brands.find(brand => {
    return brand.key === provider.primaryBrandKey
  }) : undefined

  const bestProviderName = provider?.name || providerBrand?.name || 'Unknown Provider'

  const imageSource = providerBrand?.logo ? { uri: providerBrand?.logo } : require('assets/icons/default_provider.png')


  const sortedAttempts = orderBy(transferAttempts, ['updatedAt'], ['desc'])

  const currentTransferAttempt: RetirementAssetTransferAttempt = sortedAttempts.length ? sortedAttempts[0] : undefined
  const oldTransferAttempts: RetirementAssetTransferAttempt[] = sortedAttempts.length ? tail(sortedAttempts) : undefined

  const inProgressStatuses: RetirementAssetTransferStatus[] = [
    RetirementAssetTransferStatus.INITIATED,
    RetirementAssetTransferStatus.ACTIVE,
    RetirementAssetTransferStatus.WITH_SUPPORT,
  ]
  const rejectedStatuses: RetirementAssetTransferStatus[] = [
    RetirementAssetTransferStatus.REJECTED,
    RetirementAssetTransferStatus.MODIFIED,
  ]

  const foundTracingStatuses: RetirementAssetTracingStatus[] = [
    RetirementAssetTracingStatus.FOUND_DELAY,
    RetirementAssetTracingStatus.FOUND_NEEDS_DECISION,
    RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE,
    RetirementAssetTracingStatus.COMPLETED,
    RetirementAssetTracingStatus.ABANDONED,
  ]

  const failedTracingStatuses: RetirementAssetTracingStatus[] = [
    RetirementAssetTracingStatus.NOT_FOUND,
    RetirementAssetTracingStatus.INVALID,
  ]

  const approvableTracingStatuses: RetirementAssetTracingStatus[] = [
    RetirementAssetTracingStatus.FOUND_DELAY,
    RetirementAssetTracingStatus.FOUND_NEEDS_DECISION,
  ]

  const acknowledgeableTracingStatuses: RetirementAssetTracingStatus[] = [
    RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE,
    RetirementAssetTracingStatus.INVALID,
    RetirementAssetTracingStatus.NOT_FOUND,
  ]

  const isFound = foundTracingStatuses.includes(tracingStatus)
  const isFailedTrace = failedTracingStatuses.includes(tracingStatus)
  const isApprovable = approvableTracingStatuses.includes(tracingStatus)
  const isAcknowledgable = acknowledgeableTracingStatuses.includes(tracingStatus)

  const currentTracingAttempt = tracingAttempts?.length ? tracingAttempts[0] : undefined

  const delayedExecutionDate = transferDelayExecutionDate ? format(new Date(transferDelayExecutionDate), 'do MMMM yyyy') : undefined

  const hasPolicy = foundTracingStatuses.includes(tracingStatus)
  const { data: policyDocument, error: policyDocumentError, isLoading: policyDocumentIsLoading, isFetching: policyDocumentIsFetching, refetch: refetchPolicy } = useGetRetirementAssetPolicyDocumentDetailsQuery(id, { skip: !hasPolicy })
  const policyIsPdf = policyDocument?.filename ? policyDocument.filename.split('.').pop() === 'pdf' : false

  const isInTracing = transferStatus === RetirementAssetTransferStatus.TRACING
  const isInProgress = inProgressStatuses.includes(transferStatus)
  const isWithCedingProvider = isInProgress && currentTransferAttempt?.transferStatus !== PensionTransferStatus.ONBOARDING_ASSETS
  const lastUpdate = currentTransferAttempt?.updatedAt ? new Date(currentTransferAttempt?.updatedAt) : undefined
  const isUnconfirmed = isWithCedingProvider && !transferProgressConfirmed
  const isStale = isUnconfirmed && (!lastUpdate || isBefore(lastUpdate, subWeeks(new Date(), PENDING_TRANSFER_CONSIDERED_STALE_WEEKS)))
  const isRejected = rejectedStatuses.includes(transferStatus)
  const isCompleted = transferStatus === RetirementAssetTransferStatus.COMPLETED
  const needsAcknowledge = isCompleted && !completedTransferAcknowledged

  const currentContributionSource = client?.contributionConfiguration?.source
  const factor = contributionSource === currentContributionSource ? 1
    : contributionSource === ContributionSource.EMPLOYER ? (1 / (1 + PERSONAL_TAX_RELIEF_FACTOR)) //asset is employer and current is personal
    : (1 + PERSONAL_TAX_RELIEF_FACTOR) //asset is personal and current is employer

  const undecidedAmount = monthlyContributionAmount * factor || 0
  const currentContributionAmount = pension?.regularContribution?.amount || 0
  const newContributionAmount = currentContributionAmount + undecidedAmount || 0
  
  const [ acknowledgeTransfer ] = useAcknowledgeTransferRetirementAssetMutation()
  const [ confirmProgressTransfer ] = useConfirmProgressTransferRetirementAssetMutation()
  const [ approveTransfer ] = useApproveTransferRetirementAssetMutation()
  const [ cancelTransfer ] = useCancelTransferRetirementAssetMutation()

  //Refresh policy on load
  useEffect(() => {
    if (hasPolicy) {
      refetchPolicy()
    }
  }, [])

  const handleGoToContributions = () => {
    handleAcknowledge()
    dispatch(setMakeContributionsData({
      account: personalPension,
      existingContributionConfiguration: client?.contributionConfiguration,
      existingRegularContribution: pension?.regularContribution,
      makeContributionSteps: [
        MakeContributionsStepIdentifier.REGULAR_CONTRIBUTION,
      ],
      newMonthlyContributions: newContributionAmount,
    }))
    onDismiss()
  }

  const handleGoToPension = () => {
    handleAcknowledge()
    onDismiss()
    mainAppNavigate(MainAppNavScreen.PERSONAL_PENSION)
  }

  const isRetransfer = oldTransferAttempts?.length

  const handleAcknowledgeAndDismiss = () => {
    handleAcknowledge()
    onDismiss()
  }
  
  const handleAcknowledge = () => {
    acknowledgeTransfer(id)
    ampli.transferAcknowledge({
      ...getAmplitudeRetirementAssetAttributes(asset),
      isRetransfer,
      useTracing: !isRetransfer && tracingStatus === RetirementAssetTracingStatus.COMPLETED
    })
  }

  const handleCancelTransfer = () => {
    cancelTransfer(asset.id)
    ampli.transferCancel({
      ...getAmplitudeRetirementAssetAttributes(asset),
      isRetransfer: false,
      useTracing: true,

    })
    setCancelDialogVisible(false)
    onDismiss()
  }

  const handleApproveTransfer = () => {
    approveTransfer(asset.id)
    ampli.transferApprove({
      ...getAmplitudeRetirementAssetAttributes(asset),
      isRetransfer: false,
      useTracing: true,
    })
    setApproveDialogVisible(false)
    onDismiss()
  }

  const handleConfirmProgressTransfer = () => {
    confirmProgressTransfer(asset.id)
    setConfirmProgressDialogVisible(false)
    onDismiss()
  }


  const currentStatus = isInTracing
    ? isFound
      ? `We've successfully found your pension`
      : isFailedTrace
        ? `We could not locate your pension`
        : `Your pension is currently being located by our team.`
    : isInProgress
      ? isWithCedingProvider
        ? `We requested transfer from your previous provider ${currentTransferAttempt?.transactionDate ? `on ${getActionDate(new Date(currentTransferAttempt?.transactionDate), true)}` : ``} and are awaiting their response.`
        : `Your previous provider has confirmed that they have your pension and they are preparing to transfer funds.`
      : isCompleted
        ? `Your pension transfer has been completed and received funds are now part of your ${JAR_NAME_PERSONAL} balance.`
        : isRejected
          ? `Your pension transfer was rejected by the previous provider.`
          : startCase(transferStatus)

  return (
    <CardModal visible={visible} onDismiss={onDismiss} >
      <View style={layoutStyles.cardModalTitleContainer}>
        <View></View>
        <View style={layoutStyles.cardModalTitleTextContainer}></View>
        <View>
          <MaterialCommunityIcons name={'close'} size={Sizing.x25} onPress={onDismiss} color={Colors.brand.purple1} />
        </View>
      </View>
      <View style={layoutStyles.cardModalContentContainer}>
        <ScrollView
          contentContainerStyle={{
            paddingVertical: Sizing.x20,
          }}
          showsVerticalScrollIndicator={true}
        >
        {
          isLoading
            ? <Loading />
            : error ? <ErrorScreen
              errorTryAgain={brandsError ? refetchBrands : refetchProviders}
              error={error?.data}
            /> :
          <>
            <View style={{
              alignSelf: 'center',
            }}>
              <Image source={imageSource} style={{
                width: Sizing.x100,
                height: Sizing.x100,
                resizeMode: 'contain',
                alignSelf: 'center',
              }} />
            </View>
            <View style={{paddingBottom: Sizing.x0}}>
              <Headline>{name}</Headline>
            </View>
            <View style={{
              alignSelf: 'center',
              paddingTop: Sizing.x0,
              paddingBottom: Sizing.x20,
              width: '100%',
            }}>
              <ContentDivider />
              <Subheading style={localStyles.subTitle}>{`Current Status`}</Subheading>
              <Paragraph>{currentStatus}</Paragraph>
              {
                isFound
                  ? <>
                      <ContentDivider />
                      <Subheading style={localStyles.subTitle}>{`Policy Document`}</Subheading>
                      <View style={{
                        ...Flex.row.center,
                        paddingTop: Sizing.x10,
                      }}>
                        <View style={{ paddingHorizontal: Sizing.x10}}>
                          <MaterialCommunityIcons name={'paperclip'} size={Sizing.x20} />
                        </View>
                        
                            <ExternalLinkText
                              style={{ color: Colors.brand.purple1}}
                              disabled={policyDocumentIsLoading || policyDocumentIsFetching || !!policyDocumentError}
                              url={policyDocument?.url}
                              usePdfViewer={policyIsPdf}
                            >{
                              policyDocumentIsLoading || policyDocumentIsFetching ? `Loading...`
                              : policyDocumentError ? `Error retrieving policy document` :
                              'Policy Document'
                            }</ExternalLinkText>
                      
                      </View>
                    </>
                  : <></>
              }

              {
                isInTracing ?
                  <>
                    <ContentDivider />
                    <Subheading style={localStyles.subTitle}>{`Next Steps`}</Subheading>
                    {
                        tracingStatus === RetirementAssetTracingStatus.INITIATED || tracingStatus === RetirementAssetTracingStatus.ACTIVE
                        ? <>
                            <Paragraph>{`Our Pension Finding team may be in touch by email if they need any further information to find your pension.`}</Paragraph>
                          </>
                        : tracingStatus === RetirementAssetTracingStatus.FOUND_DELAY ? <>
                            <Paragraph>{`Your pension is currently administered by ${bestProviderName}.`}</Paragraph>
                            <Paragraph>{`This pension is eligible for transfer into your Jarvis plan, and will automatically proceed into transfer execution ${delayedExecutionDate ? `on ${delayedExecutionDate}` : `soon`}`}</Paragraph>
                            <Paragraph style={Typography.fontWeight.bold}>{`Please review the attached policy document for your old pension to ensure you are happy to proceed.`}</Paragraph>
                            <Paragraph>{`It's important to check for any benefits, protections or restrictions that you may lose by transferring the pension away from the current provider - once we request the transfer`}</Paragraph>
                            <Paragraph>{`If you want to commence the transfer immediately, or cancel the transfer, you can do so ${delayedExecutionDate ? `before ${delayedExecutionDate}` : `now`}.`}</Paragraph>
                          </>
                        : tracingStatus === RetirementAssetTracingStatus.FOUND_NEEDS_DECISION ?
                          <>
                            <Paragraph>{`Your pension is currently administered by ${bestProviderName}.`}</Paragraph>
                            <Paragraph>{`From our initial review, this pension is eligible for transfer into your Jarvis plan.`}</Paragraph>
                            {/*PA-1217 - Disable auto-transfer*/}
                              {/* <Paragraph>{`This pension is eligible for transfer into your Jarvis plan, however we need you to confirm whether or not you want to proceed, due to the following:`}</Paragraph>
                              {
                                currentTracingAttempt?.foundValue > FOUND_PENSION_DECISION_MIN_VALUE ? 
                                  <BulletItem style={localStyles.text}>{`We found that the pension has a transfer value of greater than ${formatCurrencyAmount(FOUND_PENSION_DECISION_MIN_VALUE)}`}</BulletItem>
                                : currentTracingAttempt?.hasOtherSafeguardedBenefits || currentTracingAttempt?.hasOtherProtectionsOrRestrictions ? 
                                  <BulletItem style={localStyles.text}>{`We found that the pension may have benefits, protections or restrictions that you would lose by transferring it to your Jarvis plan`}</BulletItem>
                                : <BulletItem style={localStyles.text}>{`Our team spotted something unusual in the policy documentation for your old pension`}</BulletItem>
                              } */}
                            {/*PA-1217 - Disable auto-transfer*/}

                            <Paragraph style={Typography.fontWeight.bold}>{`Please review the attached policy document to decide if you want to proceed with the transfer.`}</Paragraph>
                            <Paragraph>{`It's important to check for any benefits, protections or restrictions that you may lose by transferring the pension away from the current provider - once we request the transfer.`}</Paragraph>
                            <Paragraph style={Typography.fontWeight.bold}>{`Your old provider may or may not contact you to check before fulfilling the transfer request.`}</Paragraph>
                            <Paragraph>{`If you approve, we will request electronic transfer from your old provider.`}</Paragraph>
                            <Paragraph>{`If you cancel, you can re-attempt a self-serve transfer in the future if you wish.`}</Paragraph>
                          </>
                        : tracingStatus === RetirementAssetTracingStatus.FOUND_UNTRANSFERABLE ?
                          <>
                            <Paragraph>{`Your pension is currently administered by ${bestProviderName}.`}</Paragraph>
                            <Paragraph>{`Unfortunately, the pension is inelligble for transfer into into your Jarvis plan for the following reason:`}</Paragraph>
                            {
                              !currentTracingAttempt?.isOnOrigo ? 
                                <BulletItem style={localStyles.text}>{`The pension provider does not support the electronic transfer of pensions`}</BulletItem>
                              : currentTracingAttempt?.isActive ? 
                                <BulletItem style={localStyles.text}>{`We found that your employer is still actively contributing to the pension`}</BulletItem>
                              : currentTracingAttempt?.foundValue === 0 ? 
                                <BulletItem style={localStyles.text}>{`The pension has no current financial value`}</BulletItem>
                              : currentTracingAttempt?.isDefinedBenefits || currentTracingAttempt?.hasOtherProtectionsOrRestrictions || currentTracingAttempt?.hasOtherSafeguardedBenefits? 
                                <BulletItem style={localStyles.text}>{`The pension appears to have defined benefits, protections or guarantees that you would lose by transferring it`}</BulletItem>
                              : currentTracingAttempt?.isInDrawdown ? 
                                <BulletItem style={localStyles.text}>{`The pension is already drawdown`}</BulletItem>
                              : <BulletItem style={localStyles.text}>{`Our team spotted something unusual in the policy documentation for your old pension`}</BulletItem>
                            }
                            <Paragraph>{`You may review the attached policy document, which may contain further information.`}</Paragraph>
                            <Paragraph style={Typography.fontWeight.bold}>{`Please acknowledge this message to abandon this transfer attempt so you can make changes or re-attempt transfer in the future.`}</Paragraph>
                            <Paragraph>{`You may be able to re-attempt a self-serve transfer in the future if the situation changes.`}</Paragraph>
                          </>
                        : tracingStatus === RetirementAssetTracingStatus.INVALID ?
                          <>
                            <Paragraph>{`Unfortunately, we cannot find pensions where there is a high likelihood that we would either not find a pension, or if we found one it would be ineligible for transfer to your Jarvis plan.`}</Paragraph>
                            {
                              assetType === RetirementAssetType.WORKPLACE ?
                              <>
                                <Paragraph>{`For workplace pensions, reasons could include:`}</Paragraph>
                                <BulletItem style={localStyles.text}>{`Your employment was in the Public Sector`}</BulletItem>
                                <BulletItem style={localStyles.text}>{`You has not reached the age of 22 when your employment ceased`}</BulletItem>
                                <BulletItem style={localStyles.text}>{`You were employed for more than 3 months`}</BulletItem>
                                <BulletItem style={localStyles.text}>{`You were employed for less than 2 years before the year 2000`}</BulletItem>
                              </>
                              : <></>
                            }
                            <Paragraph style={Typography.fontWeight.bold}>{`Please acknowledge this message to abandon this transfer attempt so you can make changes or re-attempt transfer in the future.`}</Paragraph>
                            <Paragraph>{`If you have since found the details for your pension, you can then perform a self-serve transfer.`}</Paragraph>
                          </>
                        : tracingStatus === RetirementAssetTracingStatus.NOT_FOUND ?
                        <>
                          <Paragraph>{`Unfortunately, we were unable to find your pension based on the details you gave us.`}</Paragraph>
                          <Paragraph style={Typography.fontWeight.bold}>{`Please acknowledge this message to abandon this transfer attempt so you can make changes or re-attempt transfer in the future.`}</Paragraph>
                          <Paragraph>{`If you have since found the details for your pension, you can then perform a self-serve transfer.`}</Paragraph>
                        </>
                        :
                        <>
                          <Paragraph>{`We were not able to find this pension.`}</Paragraph>
                          <Paragraph>{`Please contact support to understand more about why this pension could not be found.`}</Paragraph>
                          <Paragraph style={Typography.fontWeight.bold}>{`Please acknowledge this message to abandon this transfer attempt so you can make changes or re-attempt transfer in the future.`}</Paragraph>
                        </>
                    }
                    {
                      tracingStatus === RetirementAssetTracingStatus.FOUND_DELAY
                      || tracingStatus === RetirementAssetTracingStatus.FOUND_NEEDS_DECISION
                        ?
                        <>
                          <Paragraph><ExternalLinkText url={pensionFindingTermsUrl} usePdfViewer={true}>{'Pension Finding Terms Apply'}</ExternalLinkText></Paragraph>
                          <Paragraph><ExternalLinkText url={personalPensionMemberDeclarationUrl} usePdfViewer={true}>{'Member Declaration'}</ExternalLinkText></Paragraph>
                        </>
                        : <></>
                    }
                  </>
                  : needsAcknowledge ?
                  <>
                    <ContentDivider />
                    <Subheading style={localStyles.subTitle}>{`Next Steps`}</Subheading>
                    <Paragraph>{`Your pension transfer is now complete, and the transferred amount can now be seen as part of your Jarvis plan balance.`}</Paragraph>
                    {
                      undecidedAmount > 0
                        ? <>
                            <Paragraph>{`Before transferring, you told us that the pension had ${asset?.contributionSource === ContributionSource.EMPLOYER ? 'total' : 'personal'} regular contributions of ${formatCurrencyAmount(undecidedAmount || 0)}`}</Paragraph>
                            {
                              currentContributionAmount
                                ? <Paragraph style={Typography.fontWeight.bold}>{`As such, you may want to increase your Jarvis regular contributions by ${formatCurrencyAmount(undecidedAmount, 2)} to ${formatCurrencyAmount(newContributionAmount, 2)} to reflect those from your old pension.`}</Paragraph>
                                : <Paragraph style={Typography.fontWeight.bold}>{`As such, you may want to setup regular contributions to your ${JAR_NAME_PERSONAL} of ${formatCurrencyAmount(newContributionAmount, 2)} to reflect those from your old pension.`}</Paragraph>
                            }
                            
                          </>
                        : <Paragraph style={Typography.fontWeight.bold}>{`Please acknowledge or view your pension balance to complete the transfer process.`}</Paragraph>
                    }
                  </>
                  : isWithCedingProvider ?
                  <>
                    <ContentDivider />
                    <Subheading style={localStyles.subTitle}>{`Next Steps`}</Subheading>
                    <Paragraph>{`Unfortunately, after requesting transfers from providers, we do not receive any updates from them until they either accept or reject the transfer.`}</Paragraph>
                    <Paragraph>{`During this period, they may or may not contact you before accepting the transfer - in some cases, they may request further information from you in the form of a questionnaire.`}</Paragraph>
                    {
                      isStale
                        ? <Paragraph>{`If you have heard from the old provider, you can tell us below and we'll stop reminding you about it whilst you progress with them.`}</Paragraph>
                        : <></>
                    }
                    <Paragraph>{`To assist with this, we have prepared some useful information about our pension scheme, administrator and your specific investment choice:`}</Paragraph>
                    <Button mode='text' onPress={() => setAssistanceModalVisible(true)}>{`View Information`}</Button>
                  </>
                  : isRejected ?
                  <>
                    <ContentDivider />
                    <Subheading style={localStyles.subTitle}>{`Next Steps`}</Subheading>
                    <Paragraph>{`Unfortunately, we don't always receive details about why a transfer request was rejected - there may be information in the Transfer Log below.`}</Paragraph>
                    <Paragraph style={Typography.fontWeight.bold}>{`You can resolve this by either reviewing and re-attempting the transfer, or abandoning it for the time being.`}</Paragraph>
                  </>
                  : needsAcknowledge ?
                  <>
                    <ContentDivider />
                    <Subheading style={localStyles.subTitle}>{`Next Steps`}</Subheading>
                    <Paragraph>{`Let's wrap things up!`}</Paragraph>
                    <Paragraph style={Typography.fontWeight.bold}>{`Please acknowledge this message to complete this pension transfer.`}</Paragraph>
                  </>
                  : isCompleted ?
                  <></>
                  :
                  <>
                    <ContentDivider />
                    <Subheading style={localStyles.subTitle}>{`Next Steps`}</Subheading>
                    <Paragraph>{`Sit back and relax!.`}</Paragraph>
                    <Paragraph>{`Your old provider has confirmed they have your pension and are preparing to transfer the value to your Jarvis plan.`}</Paragraph>
                    <Paragraph>{`This process can take a few days to complete, but we will send you a message when the funds are received.`}</Paragraph>
                  </>
              }

              {
                isInTracing
                  ? <>
                      <ContentDivider />
                      <Subheading style={localStyles.subTitle}>{`Pension Details`}</Subheading>
                      {
                        isFound ?
                          <Paragraph>{`We're found your pension with the following details:`}</Paragraph>
                        : isFailedTrace ?
                          <Paragraph>{`We attempted to find your pension with the following details:`}</Paragraph>
                        : <Paragraph>{`We're attempting to find your pension with the following details:`}</Paragraph>
                      }
                      <BulletItem style={localStyles.text}>{`Type: ${startCase(assetType)}`}</BulletItem>
                      {
                        assetType === RetirementAssetType.WORKPLACE
                          ? <>
                              <BulletItem style={localStyles.text}>{`Employer: ${employerName}`}</BulletItem>
                              <BulletItem style={localStyles.text}>{`Employment Period: ${format(new Date(employerStartDate),'yyyy')}-${format(new Date(employerEndDate),'yyyy')}`}</BulletItem>
                            </>
                          : <BulletItem>{`Previous Provider: ${pensionProviderBrandName}`}</BulletItem>
                      }
                      <BulletItem>{`Orignal Estimated Value: ${formatCurrencyAmount(currentValue)}`}</BulletItem>
                      {
                        isFound
                          ? <BulletItem>{`Estimated Value From Provider: ${formatCurrencyAmount(currentTracingAttempt?.foundValue)}`}</BulletItem>
                          : <></>
                      }
                      {
                        isFound
                          ? <BulletItem style={localStyles.text}>{`Reference: ${currentTracingAttempt?.pensionPlanReferenceNumber}`}</BulletItem>
                          : <BulletItem style={localStyles.text}>{`Reference: ${externalProviderReference || 'Unknown'}`}</BulletItem>
                      }
                      <BulletItem style={localStyles.text}>{`Address: ${transferAddress ? buildAddressDescription(transferAddress) : 'Unknown'}`}</BulletItem>
                    </>
                : <>
                    <ContentDivider />
                    <Subheading style={localStyles.subTitle}>{`Transfer Details`}</Subheading>
                    <Paragraph>{isInProgress
                      ? `We're attempting to transfer your pension with the following details:`
                      : isRejected
                        ? `The previous provider rejected the transfer of a pension with the following details:`
                        : isCompleted
                          ? `We successfully transferred your pension with the following details:`
                          : `Your pension has the following details:`
                    }</Paragraph>
                    <BulletItem style={localStyles.text}>{`Type: ${startCase(assetType)}`}</BulletItem>
                    <BulletItem style={localStyles.text}>{`Previous Provider: ${provider?.name}`}</BulletItem>
                    <BulletItem style={localStyles.text}>{`Reference: ${externalProviderReference || 'Unknown'}`}</BulletItem>
                    <BulletItem style={localStyles.text}>{`Address: ${transferAddress ? buildAddressDescription(transferAddress) : 'Unknown'}`}</BulletItem>
                    <BulletItem>{`Estimated Transfer Value: ${formatCurrencyAmount(currentValue)}`}</BulletItem>
                    {
                      transferStatus === RetirementAssetTransferStatus.COMPLETED && currentTransferAttempt?.transferValue
                        ? <BulletItem>{`Transferred Amount: ${formatCurrencyAmount(currentTransferAttempt?.transferValue, 2)}`}</BulletItem>
                        : currentTransferAttempt?.transferValue && currentValue !== currentTransferAttempt?.transferValue
                        ? <BulletItem>{`Latest Estimate From Provider: ${formatCurrencyAmount(currentTransferAttempt?.transferValue, 2)}`}</BulletItem>
                        : <></>
                    }
                  </>
              }
              {
                isInTracing ? <></> :
                <>
                  <ContentDivider />
                  <Subheading style={localStyles.subTitle}>{`Transfer Log`}</Subheading>
                  <Paragraph>{`Any status updates for the transfer are shown below:`}</Paragraph>
                  {
                    currentTransferAttempt?.statusChanges?.map(((data, idx) => (
                      <>
                        <BulletItem key={idx} style={localStyles.text}>{getActionDate(data?.statusDate, true)}: {data?.transferStatus || data?.status} - {data?.description}</BulletItem>
                      </>
                    )))
                  }
                </>
              }
            </View>
          </>
          }
        </ScrollView>
      </View>
      {
        needsAcknowledge ?
          <>
            {
              undecidedAmount
              ? <FooterButton onPress={handleAcknowledgeAndDismiss} mode={'text'} >{'Skip Contribution Setup'}</FooterButton>
              : <FooterButton onPress={handleGoToPension} mode={'text'} >{'View Pension Balance'}</FooterButton>
            }
            <FooterButton
              onPress={undecidedAmount
                ? handleGoToContributions
                : handleAcknowledgeAndDismiss
              }
              mode={'contained'}
            >{undecidedAmount ? 'Setup Contributions' : 'Acknowledge Transfer'}</FooterButton>
          </>
        : isRejected ?
          <>
            <FooterButton onPress={onDismiss} mode={'text'} >{'Do this later'}</FooterButton>
            <FooterButton onPress={() => {
              onDismiss()
              rootNavigate('BulkRetransferIntroScreen')
            }}>{'Resolve Now'}</FooterButton>
          </>
        : isStale ?
        <>
          <FooterButton onPress={() => setConfirmProgressDialogVisible(true)} mode={'text'} >{`I've heard from the provider`}</FooterButton>
          <FooterButton onPress={onDismiss}>{'Close'}</FooterButton>
        </>          
        : isInTracing ?
          <>
            {
              isApprovable
                ? <FooterButton onPress={() => setCancelDialogVisible(true)} mode={'text'} >{'Cancel Transfer'}</FooterButton>
                : <></>
            }
            {
              isAcknowledgable || isApprovable
                ? <FooterButton
                    onPress={isApprovable
                      ? () => setApproveDialogVisible(true)
                      : handleCancelTransfer
                    }
                    mode={'contained'}
                  >{isApprovable ? 'Approve Transfer' : 'Acknowledge'}</FooterButton>
                : <FooterButton onPress={onDismiss} mode={'text'}>{'Close'}</FooterButton>
            }
          </>
        :
          <FooterButton onPress={onDismiss} mode={'text'}>{'Close'}</FooterButton>
      }
      <ConfirmationDialog
        visible={confirmProgressDialogVisible}
        title={'Are you sure?'}
        content={`Please confirm you have heard from the old provider regarding this transfer request.`}
        onCancel={() => setConfirmProgressDialogVisible(false)}
        onConfirm={handleConfirmProgressTransfer}
      />      
      <ConfirmationDialog
        visible={approveDialogVisible}
        title={'Are you sure?'}
        content={`Please confirm you wish to proceed with requesting the transfer of this pension from your old provider.`}
        onCancel={() => setApproveDialogVisible(false)}
        onConfirm={handleApproveTransfer}
      />
      <ConfirmationDialog
        visible={cancelDialogVisible}
        title={'Are you sure?'}
        content={`Please confirm you wish to cancel the transfer of this pension.`}
        onCancel={() => setCancelDialogVisible(false)}
        onConfirm={handleCancelTransfer}
      />
      <Portal>
        <TransferAssistanceModal
          client={client}
          pension={pension}
          visible={assistanceModalVisible}
          onDismiss={() => setAssistanceModalVisible(false)}
        />
      </Portal>
    </CardModal>
  )
}

const localStyles = StyleSheet.create({
  subTitle: {
    paddingTop: Sizing.x10,
    color: Colors.brand.purple2,
  },
  text: {
    textAlign: 'left',
  },
})