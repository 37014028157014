import { MaterialCommunityIcons } from '@expo/vector-icons'
import { AutoCompleteItem, ManagedAutoCompleteInput } from 'components/Inputs/ManagedAutoCompleteInput'
import { ModalProcessScreen } from 'components/Layout/ModalProcessScreen'
import { Paragraph } from 'components/Typography'
import { ConfirmationDialog } from 'components/Utility/ConfirmationDialog'
import { Logger } from 'lib/logger'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Image, View } from 'react-native'
import { useGetPensionBrandsQuery, useGetRetirementAssetQuery, useUpdateRetirementAssetMutation } from 'store/apiSlice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { updateWorkingRetirementAsset, workingRetirementAsset } from 'store/tempDataSlice'
import { Colors, Sizing, Flex } from 'styles'

export const RetirementAssetTransfer_31_TracingProvider = ({ route, navigation }) => {
  const { isRetransfer, asset, nextScreen }  = route?.params || {}

  //Temp state
  const dispatch = useAppDispatch()

  const [showDialog, setShowDialog] = useState(false)

  const workingRetirementAssetData = useAppSelector(workingRetirementAsset)

  const { data: latestAsset, isLoading: latestAssetIsLoading, error: latestAssetError, isFetching: latestAssetIsFetching, refetch: refetchLatestAsset } = useGetRetirementAssetQuery(asset.id)

  //Build autocomplete options
  const { data: brands, error: brandsError, isLoading: brandsIsLoading, refetch: refetchBrands } = useGetPensionBrandsQuery()
  const traceableBrands = brands ? brands.filter(brand => {
    return !!brand.raindropId
  }) : []
  const brandOptions: AutoCompleteItem[] = orderBy(traceableBrands, ['name'], ['asc']).map(brand => {
    return {
      value: brand.key,
      label: brand.name,
      description: brand.description,
      icon: brand?.logo
      ? () => <Image source={{ uri: brand?.logo}} style={{
        width: Sizing.x40,
        height: Sizing.x40,
        resizeMode: 'contain',
        alignSelf: 'center',
      }} />
      : () => <View style={{
          ...Flex.column.center,
          alignItems: 'center',
        }}>
          <MaterialCommunityIcons name={'bank'} size={Sizing.x30} color={Colors.neutral.black} />
      </View>
    }
  })

  const selectedBrand = brands ? brands.find(brand => {
    return brand.key === latestAsset?.pensionProviderBrandKey
  }) : undefined
  const selectedBrandIsNotTraceable = selectedBrand && !selectedBrand?.raindropId

  const [updateAssetBrand, { data: updatedAsset, error: updateAssetError, isLoading: updateAssetIsLoading }] = useUpdateRetirementAssetMutation()

  const error = brandsError || latestAssetError || updateAssetError
  const isLoading = brandsIsLoading || latestAssetIsLoading || updateAssetIsLoading

  //Setup form
  const formObj = useForm<{ pensionProviderBrandKey: string, pensionProviderBrandName: string }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      pensionProviderBrandKey: undefined, //Only set values in certain conditions - see below
      pensionProviderBrandName: undefined, //Only set values in certain conditions - see below
    },
  })
  const { handleSubmit, setValue, watch, formState: { isValid} } = formObj

  //Update form values when we have data
  useEffect(() => {
    if (latestAsset && brands) {
      if (selectedBrand && !selectedBrandIsNotTraceable) {
        setValue('pensionProviderBrandKey', selectedBrand?.key, { shouldDirty: true })
        setValue('pensionProviderBrandName', selectedBrand?.name, { shouldDirty: true })
      }
    }
  }, [latestAsset, brands])
  
  
  const onSubmit = attributes => {
    const workingUpdate = {
      ...attributes,
      tracingRequested: true,
    }
    
    dispatch(updateWorkingRetirementAsset(workingUpdate))
    Logger.info(`Updating asset brand... ${attributes?.pensionProviderBrandName}`)
    updateAssetBrand({
      id: asset?.id,
      tracingRequested: false,
      pensionProviderBrandKey: attributes?.pensionProviderBrandKey || null,
      pensionProviderBrandName: attributes?.pensionProviderBrandName,
      //Clear pensionProviderId if brand has changed
      pensionProviderId: selectedBrand.key !== attributes?.pensionProviderBrandKey
        ? null
        : undefined,
    })
  }

  //Navigate when we have updatedAsset
  useEffect(() => {
    if (updatedAsset) {
      navigation.navigate(nextScreen)
    }
  }, [updatedAsset])

  const goToResult = () => {
    setShowDialog(false)
    navigation.navigate('Result')
  }

  const pensionProviderBrandKey = watch('pensionProviderBrandKey')
  const pensionProviderBrandName = watch('pensionProviderBrandName')
  
  return (
    <ModalProcessScreen
      error={error}
      errorTryAgain={brandsError ? refetchBrands : latestAssetError ? refetchLatestAsset : handleSubmit(onSubmit)}
      isLoading={isLoading}
      loadingMessage={updateAssetIsLoading ? ['Saving...'] : undefined}
      buttonTitle={pensionProviderBrandName
        ? `Confirm`
        : `Make a Selection`
      }
      buttonAction={handleSubmit(onSubmit)}
      enableButton={!!pensionProviderBrandKey}
      headline={selectedBrand
        ? selectedBrandIsNotTraceable
          ? `Select a different provider brand`
          : `Confirm pension provider brand`
        : `Select a provider brand`
      }
      subHeading={selectedBrand
        ? selectedBrandIsNotTraceable
          ? `We can currently only find pensions from selected providers`
          : `Please confirm we have the right provider to find your pension`
        : `Please pick a provider to help us find your pension`
      }
      allowTextButton={!pensionProviderBrandKey}
      textButtonTitle={`I can't identify the right one`}
      textButtonAction={() => setShowDialog(true)}
    >
      <ManagedAutoCompleteInput
        name={'pensionProviderBrandKey'}
        placeholder={'Tap to select...'}
        allowFreeText={true}
        textCaptureFieldName={'pensionProviderBrandName'}
        formObj={formObj}
        modalTitle={'Find Pension Brand'}
        required={true}
        dataSet={brandOptions}
      />
      <Paragraph>{selectedBrand
        ? selectedBrandIsNotTraceable
          ? `Unfortunately, we are currently unable to find pensions for ${latestAsset?.pensionProviderBrandName}. If you recognize another related brand name on the list, please select that one instead.`
          : `Providing the pension brand with whom you set up your personal pension will increase the chance of us locating it. Please check any documentation you have to identify the best match.`
        : `In order to find personal pensions, we need to know the pension brand. Please check any documentation you have to identify the best match.`
      }</Paragraph>
      <ConfirmationDialog
        visible={showDialog}
        onCancel={() => setShowDialog(false)}
        title={'We need to identify the provider'}
        content={'We can only find your personal pension if you select one of the providers above.\n\nIf you prefer, you can save here and come back later once you have the details.'}
        illustrationFilename={'support.png'}
        confirmLabel={`Save Here`}
        cancelLabel={`Try again`}
        onConfirm={goToResult}
      />
    </ModalProcessScreen>
  )
}
